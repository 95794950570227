<template>
  <b-card
    class="mb-3"
    body-class="d-flex"
    v-if="insurance_coverage && insurance_coverage.length"
  >
    <div class="w-100">
      <div class="d-flex justify-content-between mb-4">
        <strong class="d-block text-left mb-1">
          Seguro e cobertura de riscos
        </strong>

        <router-link to="techinical">Resultado Técnico-Operacional</router-link>
      </div>

      <b-table bordered :items="insurance_coverage" :fields="fields" small>
        <template #cell(is_accepted)="data">
          <div class="mx-auto text-left">
            <span
              class="d-flex align-items-center gap-3"
              v-if="data.item.is_accepted"
            >
              <b-icon-check-circle class="text-success " />
              <span>Atende</span>
            </span>
            <span class="d-flex align-items-center gap-3" v-else>
              <b-icon-x-circle class="text-danger" />
              <span>Não Atende</span>
            </span>
          </div>
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    insurance_coverage: {
      type: [Array],
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "description",
          label: "Seguro",
        },

        {
          key: "is_accepted",
          label: "Poderação",
          tdClass: "px-3",
          thClass: "px-3",
        },
      ],
    };
  },
  methods: {
    rowClass(item) {
      if (item.status === "score") return "table-success";
    },
  },
};
</script>
