<template>
  <div>
    <cards-list grid="2" gap="16">
      <CardCompany
        part="Tomador"
        class="h-100 min-h-40"
        :enterprise="enterprise.borrower"
      />
      <CardCompany
        part="Prestador"
        class="h-100 min-h-40"
        :enterprise="enterprise.provider"
      />
    </cards-list>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import CardCompany from "./CardCompany.vue";

export default {
  data() {
    return {
      loading: false,
      service_id: "",
      provider_id: "",
      enterprise: {},
    };
  },
  components: {
    CardCompany,
  },
  methods: {
    ...mapActions(["get_parts", "select_provider_winner"]),
    getEnterprise() {
      this.get_parts({
        provider_id: this.provider_id,
        service_id: this.service_id,
      }).then(({ data }) => {
        this.enterprise = data;
      });
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id;
    this.getEnterprise();
  },
};
</script>
