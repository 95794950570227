<template>
  <div>
    <Header
      :back="
        `/homologation/${homologation_id}/service/${service_id}/${user.role}/validation`
      "
    />
    <div class="content-wrapper">
      <div class="d-flex">
        <div>
          <context-menu ref="context" style="position: sticky; top:60px">
            <b-button
              :variant="report === menu.key ? 'primary' : 'link'"
              v-for="(menu, index) in menus"
              :key="index"
              @click="handleSelectMenu()"
              :to="menu.key"
              class="text-left d-flex align-items-center px-2 py-4 line-height-1 mb-1 gap-2"
            >
              <small>
                <b-icon
                  size="xl"
                  class="font-lg"
                  :icon="menu.icon"
                  :class="report === menu.key ? 'text-white' : 'text-dark'"
                />
              </small>
              <strong> {{ menu.label }}</strong>
            </b-button>
          </context-menu>
        </div>
        <div class="w-100">
          <div class="d-flex">
            <reports />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "../../Header";
import Reports from "./Reports";

export default {
  components: {
    Header,
    Reports,
  },
  data() {
    return {
      serviceScope: {},
      homologation_id: "",
      report: "",
      service_id: "",
      provider_id: "",
      providers: [],
      menus: [
        {
          key: "general",
          label: "Relatório Resumido",
          icon: "layout-text-sidebar-reverse",
        },
        {
          key: "report",
          label: "Relatório completo",
          icon: "receipt",
        },
        // {
        //   key: "parts",
        //   label: "Partes",
        //   icon: "people-fill",
        // },

        // {
        //   key: "costs",
        //   label: "Custos",
        //   icon: "cash",
        // },
        // {
        //   key: "fundamentals",
        //   label: "Fundamentos",
        //   icon: "journal-check",
        // },
        // {
        //   key: "financial",
        //   label: "Resultado Econômico-Financeiro",
        //   icon: "table",
        // },

        // {
        //   key: "techinical",
        //   label: "Resultado Técnico-Operacional",
        //   icon: "wrench",
        // },

        // {
        //   key: "legal",
        //   label: "Resultado Legal-Trabalhista",
        //   icon: "award-fill",
        // },
        // {
        //   key: "regularity",
        //   label: "Resultado Regularidade",
        //   icon: "patch-check",
        // },
      ],
    };
  },
  computed: {
    ...mapGetters(["user", "result_validation_general"]),
  },

  methods: {
    ...mapActions([
      "get_service_scope",
      "hide_application_header",
      "get_service_validation_result",
      "get_all_provider",
    ]),

    handleSelectMenu() {
      this.$refs.context.close();
    },
  },
  beforeDestroy() {
    this.hide_application_header(true);
  },

  mounted() {
    this.hide_application_header(false);
    this.homologation_id = this.$route.params.homologation_id;
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id;
  },
  watch: {
    $route: {
      handler({ params }) {
        this.report = params.report;
      },
      immediate: true,
    },
    result_validation_general: {
      handler(result) {
        if (result?.providers) {
          this.serviceScope = result;
          const providers = result.providers.filter(
            ({ id }) => id !== this.provider_id
          );
          this.providers = [...new Set([...providers])];
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.in-comparation {
  border: 1px solid $primary;
}
</style>
