<template>
  <div v-if="enterprise && enterprise.id" class="h-100">
    <b-card class="mb-3 h-100" body-class="d-flex flex-column align-item-end">
      <div class="d-flex justify-content-between mb-2">
        <strong class="d-block text-left mb-3">
          {{ part }}
        </strong>
      </div>
      <h5 class="d-block mb-3 min-h-6">
        {{ enterprise.social_reason }}
      </h5>
      <div class="mb-3">
        Nome Fantasia: <strong>{{ enterprise.fantasy_name }}</strong>
      </div>
      <cards-list grid="2">
        <div>
          <div class="mb-2">
            CNPJ:
            <strong>{{ enterprise.cnpj | VMask("##.###.###/####-##") }}</strong>
          </div>
          <div class="mb-2">
            Situação Cadastral:
            <strong>{{ enterprise.active ? "Ativa" : "Baixada" }}</strong>
          </div>
        </div>
        <div>
          <div class="mb-2">
            Data de abertura:
            <strong>{{ enterprise.founded_at | date }}</strong>
          </div>
          <div>
            Natureza Jurídica: <strong>{{ enterprise.segment }}</strong>
          </div>
        </div>
        <div class="mb-2">
          E-mail: <strong>{{ enterprise.email }}</strong>
        </div>
      </cards-list>
      <hr />
      <div v-if="enterprise && enterprise.address">
        <strong class="d-block mb-1">Localização</strong>
        <section>
          <span>
            {{ enterprise.address.place }}, {{ enterprise.address.number }},
            {{ enterprise.address.city }}, {{ enterprise.address.state }} -
            {{ enterprise.address.zip_code | VMask("#####-###") }}
          </span>
        </section>
        <gmap-map
          :center="center"
          :zoom="14"
          class="mt-3 w-100"
          style="height: 200px"
          :options="{
            strokeColor: '#347cff',
            zoomControl: false,
            scaleControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            disableDoubleClickZoom: true,
            draggable: false,
            scrollwheel: false,
            panControl: false,
          }"
        >
          <gmap-marker :position="center" :clickable="false" :draggable="false">
          </gmap-marker>
        </gmap-map>
      </div>
      <hr />
      <div v-if="enterprise && enterprise.responsibles">
        <p>Responsáveis legais</p>
        <b-card
          class="my-3"
          body-class="px-3 py-2"
          v-for="(user, index) in enterprise.responsibles"
          :key="index"
        >
          <router-link :to="`/users/detail/${user.id}`" target="_blank">
            <div class="align-items-center">
              <section class="d-flex align-items-center gap-2">
                <avatar
                  :photo="user.photo"
                  :is_online="user.is_online"
                  :name="user.name"
                />
                <div>
                  <strong class="d-block w-100">
                    {{ user.name }}
                  </strong>
                  <div>
                    <small>{{ user.email }}</small>
                  </div>
                </div>
              </section>
            </div>
          </router-link>
        </b-card>
      </div>
    </b-card>
  </div>
</template>
<script>
import { gmapApi } from "vue2-google-maps";

export default {
  data() {
    return {
      center: {},
    };
  },

  computed: {
    google: gmapApi,
  },

  props: {
    enterprise: {
      type: Object,
      require: true,
    },
    part: String,
  },
  watch: {
    enterprise: {
      handler(item) {
        if (item?.address) {
          const coordinates = item.address.coordinates.coordinates;
          this.center = {
            lat: coordinates[1],
            lng: coordinates[0],
          };
        }
      },
      immediate: true,
    },
  },
};
</script>
