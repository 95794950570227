<template>
  <div class="w-100">
    <general v-if="report === 'general'" />
    <fundamentals v-if="report === 'fundamentals'" />
    <parts v-if="report === 'parts'" />
    <economic-financial v-if="report === 'financial'" />
    <complete-validation v-if="report === 'report'" />
  </div>
</template>

<script>
import General from "./General";
import Parts from "./Parts";
import Fundamentals from "./Fundamentals";
import EconomicFinancial from "./EconomicFinancial";
import CompleteValidation from "./CompleteValidation";

export default {
  components: {
    General,
    Parts,
    Fundamentals,
    EconomicFinancial,
    CompleteValidation,
  },

  data() {
    return {
      report: "general",
    };
  },

  watch: {
    $route: {
      handler({ params }) {
        this.report = params.report;
      },
      immediate: true,
    },
  },
};
</script>
