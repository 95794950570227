<template>
  <b-card class="mb-3" body-class="d-flex">
    <div class="w-100">
      <div class="d-flex justify-content-between mb-4">
        <strong class="d-block text-left mb-1">
          Demonstrações financeiras
        </strong>
        <router-link to="financial">Resultado Econômico-Financeiro</router-link>
      </div>
      <div class="d-flex gap-4">
        <section class="w-100">
          <span class="d-block mb-3">
            Indicadores de crescimento <strong>Anual</strong>
          </span>

          <b-table bordered :items="cagr.anual" :fields="fieldsAnual" small>
            <template #cell(revenue)="data">
              <span
                class="text-left ml-1 d-block"
                :class="`text-${statusCagr(data.item.revenue).variant}`"
              >
                {{ statusCagr(data.item.revenue).text }}
              </span>
            </template>
            <template #cell(profit)="data">
              <span
                class="text-left ml-1 d-block"
                :class="`text-${statusCagr(data.item.profit).variant}`"
              >
                {{ statusCagr(data.item.profit).text }}
              </span>
            </template>
          </b-table>
        </section>
        <hr />
        <section class="w-100">
          <span class="d-block mb-3">
            Indicadores de crescimento <strong>Trimestral</strong>
          </span>
          <b-table bordered :items="cagr.quarter" :fields="fieldsQuarter" small>
            <template #cell(revenue)="data">
              <span
                class="text-left ml-1 d-block"
                :class="`text-${statusCagr(data.item.revenue).variant}`"
              >
                {{ statusCagr(data.item.revenue).text }}
              </span>
            </template>
            <template #cell(profit)="data">
              <span
                class="text-left ml-1 d-block"
                :class="`text-${statusCagr(data.item.profit).variant}`"
              >
                {{ statusCagr(data.item.profit).text }}
              </span>
            </template>
          </b-table>
        </section>
      </div>
    </div>
  </b-card>
</template>
<script>
export default {
  props: {
    cagr: Object,
  },

  data() {
    return {
      fieldsQuarter: [
        {
          key: "revenue",
          label: "CAGR receitas 4 trimestres",
          formatter: (value) => `${value * 100}%`,
        },
        {
          key: "profit",
          label: "CAGR lucro 4 trimestres",
          formatter: (value) => `${value * 100}%`,
        },
      ],
      fieldsAnual: [
        {
          key: "revenue",
          label: "CAGR receitas 3 ANOS",
          formatter: (value) => `${value * 100}%`,
        },
        {
          key: "profit",
          label: "CAGR lucro 3 ANOS",
          formatter: (value) => `${value * 100}%`,
        },
      ],
      series: [44, 55],
      chartOptions: {
        colors: ["#1568ff", "#f1f5f9"],
        legend: {
          show: false,
        },
      },
    };
  },
  methods: {
    statusCagr(percent) {
      const value = percent * 100;

      const variants = ["muted", "danger", "muted"];

      const texts = [
        "Não houve crescimento",
        `Redução de ${value}%`,
        `Crescimento de ${value}%`,
      ];

      let index = 0;

      switch (true) {
        case value > 0:
          index = 2;
          break;
        case value < 0:
          index = 1;
          break;
        case value === 0:
          index = 0;
      }

      return {
        variant: variants[index],
        text: texts[index],
      };
    },
  },
};
</script>
