<template>
  <b-card class="mb-3" body-class="d-flex">
    <div class="w-100">
      <div class="d-flex justify-content-between mb-4">
        <strong class="d-block text-left mb-1">
          Gestão de pessoas
        </strong>

        <router-link to="legal">Resultado Legal-Trabalhista</router-link>
      </div>

      <div class="d-flex">
        <div class="w-100">
          <ApexCharts
            class="w-100 chart-data"
            type="area"
            height="350"
            ref="chartPeople"
            :options="chartOptions"
            :series="series"
          />
          <div class="mx-4">
            <section class="d-flex w-100 justify-content-between">
              <strong>MÉDIA</strong>
              <strong>{{ (chart.average) }}%</strong>
            </section>
            <hr />
            <section class="d-flex w-100 justify-content-between">
              <strong>ACUMULADA</strong>
              <strong>{{ (chart.accumulated) }}% </strong>
            </section>
          </div>
        </div>
        <div style="min-width: 300px">
          <b-table bordered :items="chart.data" :fields="fields" small>
            <template #cell(value)="data">
              <span class="text-left mx-3 d-block mb-2">
                {{ data.item.value }}%
              </span>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </b-card>
</template>
<script>
import ApexCharts from "vue-apexcharts";

export default {
  components: {
    ApexCharts,
  },

  props: {
    people: {
      type: Object,
    },
  },

  data() {
    return {
      values: null,
      chart: {},
      fields: [
        {
          key: "period",
          label: "Período",
          colspan: 5,
        },
        {
          key: "value",
          label: "Rotatividade",
        },
      ],
      series: [
        {
          name: "Rotatividade",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "area",
          height: 350,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        yaxis: {
          opposite: false,
          labels: {
            formatter: function(value) {
              return value + "%";
            },
          },
        },
        xaxis: {
          categories: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
          labels: {
            trim: true,
            minHeight: 80,
            maxHeight: 120,
            style: {
              cssClass: 'apexcharts-xaxis-label',
            },
          },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
        },
        legend: {
          horizontalAlign: "left",
        },
      }
    };
  },

  watch: {
    people: {
      handler(event) {
        if (!event.data) return;

        this.chart = event;

        const values = this.chart.data.flatMap((item) => [item.value]);

        const labels = this.chart.data.flatMap((item) => [item.period]);

        this.series[0].data = values;

        this.$nextTick(() => {
          this.chartOptions.labels = labels;
          this.$refs.chartPeople.refresh();
        });
      },
      immediate: true,
    },
  },
};
</script>
