<template>
  <div v-if="provider && provider.id">
    <b-card
      class="mb-3 min-h-26"
      body-class="d-flex justify-content-between flex-column align-item-end"
    >
      <div class="d-flex justify-content-between mb-2">
        <strong class="d-block text-left mb-3">
          Prestador
        </strong>
        <!-- <router-link to="parts">Partes</router-link> -->
      </div>
      <h5 class="d-block mb-3">
        {{ provider.social_reason }}
      </h5>
      <cards-list grid="3">
        <div>
          <div class="mb-2">
            CNPJ:
            <strong>{{ provider.cnpj | VMask("##.###.###/####-##") }}</strong>
          </div>
          <div class="mb-2">
            Situação Cadastral:
            <strong>{{ provider.active ? "Ativa" : "Baixada" }}</strong>
          </div>
          <div>
            Nome Fantasia: <strong>{{ provider.fantasy_name }}</strong>
          </div>
        </div>
        <div>
          <div class="mb-2">
            Data de abertura:
            <strong>{{ provider.founded_at | date }}</strong>
          </div>
          <div>
            Natureza Jurídica: <strong>{{ provider.segment }}</strong>
          </div>
        </div>
        <div>
          <div class="mb-2">
            E-mail: <strong>{{ provider.email }}</strong>
          </div>
          <div>CEP: <strong>31620-460</strong></div>
        </div>
      </cards-list>
    </b-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      provider: {},
    };
  },

  computed: {
    ...mapGetters(["result_validation_general"]),
  },

  methods: {
    ...mapActions(["get_provider"]),
  },

  watch: {
    result_validation_general: {
      handler(result) {
        if (result) {
          this.provider = result.provider;
        }
      },
      deep: true,
    },
  },
};
</script>
