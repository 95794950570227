<template>
  <b-card class="mb-3">
    <div class="w-100">
      <div class="d-flex justify-content-between mb-4">
        <strong class="d-block text-left mb-1">
          Localização da base
        </strong>
        <router-link to="techinical">Resultado Técnico-Operacional</router-link>
      </div>
      <div class="d-flex">
        <gmap-map :center="center" :zoom="4" class="map-report mr-4">
          <gmap-polyline
            :path.sync="path"
            :options="{ strokeColor: '#347cff' }"
          />
          <gmap-marker
            v-for="(marker, index) in markers"
            :position="marker.position"
            :clickable="false"
            :draggable="false"
            @click="center = marker.position"
            :key="index"
          >
          </gmap-marker>
        </gmap-map>
        <div class=" no-border" body-class="p-md-2 p-0">
          <div class="mt-2">
            <p>{{ provider.social_reason }}</p>
            <table class="w-100 mb-3">
              <tr>
                <td colspan="2"><strong>Denominação</strong></td>
                <td>{{ provider.fantasy_name }}</td>
              </tr>
              <tr>
                <td colspan="2"><strong>CNPJ</strong></td>
                <td>11.167.007/0001-19</td>
              </tr>
              <tr>
                <td colspan="2"><strong>Inscrição estadual</strong></td>
                <td>ISENTA</td>
              </tr>
              <tr>
                <td colspan="2"><strong>Telefone</strong></td>
                <td>31 3444-4615</td>
              </tr>
            </table>

            <section class="d-flex">
              <b-icon icon="map" class="mr-2 mt-1" />
              <p>
                {{ concatAddress("provider") }}
              </p>
            </section>
          </div>
          <hr />
          <div>
            <p>Distância da base operacional</p>
            <table class="mb-3">
              <tr>
                <td>Desejado:</td>
                <td class="pl-3">
                  <strong>Até {{ distanceDesired | number }} km</strong>
                </td>
              </tr>
              <tr>
                <td>Aferido:</td>
                <td class="pl-3">
                  <b-badge
                    class="justify-content-center"
                    :variant="distance < distanceDesired ? 'success' : 'danger'"
                  >
                    {{ distance | number }}km
                  </b-badge>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <p class="mt-3 mb-0">
      A empresa está localizada a <strong>{{ distance | number }}km</strong> do
      tomador.
    </p>
  </b-card>
</template>
<script>
import { gmapApi } from "vue2-google-maps";

export default {
  props: {
    location_base: {
      type: Object,
    },
  },
  data() {
    return {
      location: {
        address: {},
      },
      provider: {
        address: {},
      },
      distanceDesired: null,
      distance: null,
      borrower: {},
      center: {
        lat: 0.0,
        lng: 0.0,
      },
      path: [],
      markers: [
        {
          position: {
            lat: 0.0,
            lng: 0.0,
          },
        },
        {
          position: {
            lat: 0.0,
            lng: 0.0,
          },
        },
      ],
    };
  },
  computed: {
    google: gmapApi,
  },
  methods: {
    concatAddress(type) {
      let address = [];
      if (!this[type]?.address) {
        return;
      }
      address.push(this[type].address.place);
      address.push(this[type].address.number);
      address.push(this[type].address.neighborhood);
      address.push(this[type].address.city);
      address.push(this[type].address.state);
      address = address.filter((item) => item !== "");
      return address.join(", ");
    },
  },
  watch: {
    location_base: {
      handler(data) {
        if (!data) return;
        this.location = data;
        this.hasSnapshot = true;
        this.distance = (data.distance.measured / 1000).toFixed(2);
        this.distanceDesired = data.distance.desired / 1000;
        this.borrower = data.borrower;
        this.provider = data.provider;

        this.path = data.path.coordinates;
        this.markers[0].position.lng = data.origin.coordinates.lng;
        this.markers[0].position.lat = data.origin.coordinates.lat;
        this.markers[1].position.lng = data.destination.coordinates.lng;
        this.markers[1].position.lat = data.destination.coordinates.lat;

        this.center = data.center.coordinates;
      },
    },
  },
};
</script>
<style lang="scss">
.map-report {
  width: 500px;
  border-radius: $radius;
  overflow: hidden;
}
</style>
