<template>
  <b-card class="mb-3" body-class="d-flex" v-if="costs">
    <div class="w-100">
      <div class="d-flex justify-content-between mb-4">
        <strong class="d-block text-left mb-1">
          Analise de custos
        </strong>
        <router-link to="costs">Ver custos</router-link>
      </div>
      <div>
        <p>
          <strong>{{ costs.percent || 0 * 100 }}%</strong> de responsabilidade
          subsidiária do tomador nas obrigações financeiras do prestador de
          serviços
        </p>
        <b-table
          striped
          :items="costs.data"
          :fields="fields"
          small
          thead-class="d-none"
          :tbody-tr-class="rowClass"
        />
      </div>
    </div>
  </b-card>
</template>
<script>
import { formatMoney } from "@/helpers/formatMoney";

export default {
  props: {
    costs: Object,
  },

  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Despesa",
          sortable: false,
          colspan: 5,
        },
        {
          key: "value",
          label: "Valor",
          sortable: false,
          formatter: (value) => formatMoney(value),
        },
      ],
    };
  },
  methods: {
    rowClass(item) {
      return item.label === "Total" && "font-weight-bold";
    },
  },
};
</script>
