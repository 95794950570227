<template>
  <b-card class="mb-3" body-class="d-flex">
    <div class="w-100">
      <div class="d-flex justify-content-between mb-4">
        <strong class="d-block text-left mb-1">
          Atividade Econômica
        </strong>

        <router-link to="techinical">Resultado Técnico-Operacional</router-link>
      </div>
      <p>
        De Acordo com a consulta realizada, a empresa possui
        <strong>2</strong> atividaes econômicas compatíveis com perfil dos
        serviços que serão contratados e exigidos pelo Tomador de Serviço.
      </p>

      <b-table
        bordered
        :items="economic_activity.results"
        :fields="fields"
        small
      >
        <template #cell(is_compatible)="data">
          <span class="d-flex gap-3" v-if="data.item.is_compatible">
            <b-icon-check-circle class="text-success" />
            <span>Compatível</span>
          </span>
          <span v-else class="d-flex gap-3">
            <b-icon-x-circle class="text-danger" /> <span>Incompatível</span>
          </span>
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    economic_activity: [Object, Array],
  },

  data() {
    return {
      fields: [
        {
          key: "code",
          label: "Código CNAE",
          colspan: 5,
        },
        {
          key: "description",
          label: "Descrição",
        },
        {
          key: "is_compatible",
          label: "Poderação",
        },
      ],
    };
  },
};
</script>
