<template>
  <b-card>
    <div class="mt-2 min-h-57">
      <h2>Resultado Fundamentos Econômico-Financeiro</h2>
      <FundoTable
        v-if="selectedTable && selectedTable.title"
        hide_table
        :data="{
          indicators: selectedTable.indicators,
          total: selectedTable.total,
        }"
      />
    </div>
  </b-card>
</template>
<script>
import FundoTable from "../Fundamentals/FundoTable";
import { mapActions } from "vuex";

export default {
  components: {
    FundoTable,
  },

  data() {
    return {
      selectedTable: {},
    };
  },

  methods: {
    ...mapActions(["get_fundamentals"]),

    handleSelectTable(table) {
      this.selectedTable = table;
      if (table?.indicators && table.total) {
        this.indicators = table.indicators;
        this.total = table.total;
      }
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id;

    const table = {
      title: "Econômico Financeiro",
      total: {
        grade: 8,
        weight: 0.25,
        ponderation: 2.5,
      },
      indicators: [
        {
          id: 1,
          name: "Entradas",
          result_source: {
            id: 1,
            name: "Lançamento Contábil",
            description: "",
            source: "/accounting-account/",
            content_type: {
              id: 57,
              app_label: "economic_financial",
              model: "accountingaccount",
            },
          },
          weight: 8,
          score: 8,
          result: 17749.73,
          ranges: [
            {
              min: 10000,
              max: 12500,
              value: 0,
              applied: false,
            },
            {
              min: 12500,
              max: 15000,
              value: 2,
              applied: false,
            },
            {
              min: 15000,
              max: 17500,
              value: 4,
              applied: false,
            },
            {
              min: 17500,
              max: 20000,
              value: 6,
              applied: true,
            },
            {
              min: 20000,
              max: 22500,
              value: 8,
              applied: false,
            },
            {
              min: 22500,
              max: 25000,
              value: 10,
              applied: false,
            },
          ],
          grade: 35.400000000000006,
          order: 0,
        },
      ],
    };
    this.handleSelectTable(table);
  },
};
</script>
