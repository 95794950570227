<template>
  <div class="mr-1 p-0 w-100">
    <card-provider />
    <card-validation-detail :validation="validation" />
    <card-fundamentals :fundamentals="fundamentals" />
    <card-costs :costs="costs" />
    <!-- <card-financial-demonstrations :cagr="cagr" /> -->
    <card-economical-activity :economic_activity="economic_activity" />
    <card-location-base :location_base="location_base" />
    <card-insurance-coverage :insurance_coverage="insurance_coverage" />
    <card-people :people="people" />
    <card-credit-inquirit :credit_inquirity="credit_inquirity" />
  </div>
</template>
<script>
import CardProvider from "./CardProvider";
import { mapActions } from "vuex";
import CardFundamentals from "./CardFundamentals";
import CardCosts from "./CardCosts";
import CardValidationDetail from "./CardValidationDetail";
import CardFinancialDemonstrations from "./CardFinancialDemonstrations";
import CardEconomicalActivity from "./CardEconomicalActivity";
import CardInsuranceCoverage from "./CardInsuranceCoverage";
import CardPeople from "./CardPeople";
import CardCreditInquirit from "./CardCreditInquirit";
import CardLocationBase from "./CardLocationBase";

export default {
  components: {
    CardProvider,
    CardFundamentals,
    CardCosts,
    CardValidationDetail,
    CardFinancialDemonstrations,
    CardEconomicalActivity,
    CardInsuranceCoverage,
    CardPeople,
    CardCreditInquirit,
    CardLocationBase,
  },

  data() {
    return {
      fundamentals: [],
      costs: {},
      cagr: {},
      economic_activity: [],
      insurance_coverage: [],
      validation: {},
      location_base: {},
      credit_inquirity: {},
      people: {},
      provider_id: "",
      service_id: "",
      loading: false,
    };
  },

  methods: {
    ...mapActions(["get_service_validation_result"]),

    getServiceValidation(provider_id) {
      this.get_service_validation_result({
        service_id: this.service_id,
        provider_id,
      }).then(({ data }) => {
        this.fundamentals = data.fundamentals;
        this.costs = data.costs;
        this.cagr = data.cagr;
        this.economic_activity = data.economic_activity;
        this.location_base = data.location_base;
        this.people = data.people;
        this.credit_inquirity = data.credit_inquirity;
        this.insurance_coverage = data.insurance_coverage ?? [];
        this.validation = data;
      });
    },
  },

  mounted() {
    const params = this.$route.params;

    this.provider_id = params.provider_id;
    this.service_id = params.service_id;
  },

  watch: {
    provider_id: {
      handler(provider_id) {
        const recursive = () => {
          if (provider_id && !this.service_id) {
            return recursive();
          }
          this.getServiceValidation(provider_id);
        };
        recursive();
      },
      deep: true,
    },
  },
};
</script>
