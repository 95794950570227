<template>
  <div class="min-h-70 bg-white p-3">
    <div class="d-flex align-items-center justify-content-between mb-2 gap-3">
      <strong>Relatório geral</strong>
      <div class="d-flex align-items-center justify-content-between gap-3">
        <b-button
          variant="danger"
          class="d-flex align-items-center gap-2"
          v-b-modal.modal-send-report
        >
          <b-icon-file-earmark-spreadsheet />
          <span> Solicitar PDF</span>
        </b-button>
      </div>
    </div>

    <skeleton height="50px" rows="50" grid="1" gap="8" v-if="isBusy" />
    <iframe
      v-if="service_id && provider_id"
      :src="`${report_url}/${service_id}/${provider_id}?hide_header=true`"
      @load="iframeIsLoaded"
      frameborder="0"
      width="100%"
      height="100%"
      class="min-h-80 iframe"
    />
    <b-modal
      size="md"
      centered
      no-close-on-backdrop
      title="Solicitar relatório"
      id="modal-send-report"
    >
      <div>
        Um modelo deste relatório em PDF será enviado para o email:
        <strong>{{ user.enterprise.email }}</strong>
      </div>
      <template #modal-footer="{ close }">
        <b-button :disabled="loading" variant="link" @click="close()">
          Cancelar
        </b-button>
        <b-button
          @click="hlandleRequestReport()"
          :disabled="loading"
          variant="primary"
          class="d-flex align-items-center"
        >
          <b-spinner v-if="loading" small class="mr-2" />
          <span>Confirmar envio</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      report_url: `${process.env.VUE_APP_MICROSERVICE_URL}/report`,
      isBusy: false,
      loading: false,
      service_id: "",
      provider_id: "",
    };
  },

  computed: {
    ...mapGetters(["user", "service_scope"]),
  },

  methods: {
    ...mapActions(["request_report", "show_toast"]),
    iframeIsLoaded() {
      this.isBusy = false;
    },

    hlandleRequestReport() {
      this.loading = true;
      const borrower = this.service_scope?.homologation?.borrower?.id;

      const params = {
        borrower,
        service: this.service_id,
        provider: this.provider_id,
        email: user.email,
      };
      this.request_report(params).then(({ data }) => {
        this.loading = false;
        if (data.created_at) {
          this.$bvModal.hide("modal-send-report");
          this.show_toast({
            message:
              "Em instantes você receberá em seu e-mail uma cópia deste relatório!",
            type: "success",
          });
          return;
        }
        this.show_toast({
          message:
            "Tivemos um problema para gerar o relatório, tente novamente mais tarde!",
          type: "error",
        });
      });
    },
  },

  mounted() {
    this.isBusy = true;
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id;
  },
};
</script>
