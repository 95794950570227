var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.enterprise && _vm.enterprise.id)?_c('div',{staticClass:"h-100"},[_c('b-card',{staticClass:"mb-3 h-100",attrs:{"body-class":"d-flex flex-column align-item-end"}},[_c('div',{staticClass:"d-flex justify-content-between mb-2"},[_c('strong',{staticClass:"d-block text-left mb-3"},[_vm._v(" "+_vm._s(_vm.part)+" ")])]),_c('h5',{staticClass:"d-block mb-3 min-h-6"},[_vm._v(" "+_vm._s(_vm.enterprise.social_reason)+" ")]),_c('div',{staticClass:"mb-3"},[_vm._v(" Nome Fantasia: "),_c('strong',[_vm._v(_vm._s(_vm.enterprise.fantasy_name))])]),_c('cards-list',{attrs:{"grid":"2"}},[_c('div',[_c('div',{staticClass:"mb-2"},[_vm._v(" CNPJ: "),_c('strong',[_vm._v(_vm._s(_vm._f("VMask")(_vm.enterprise.cnpj,"##.###.###/####-##")))])]),_c('div',{staticClass:"mb-2"},[_vm._v(" Situação Cadastral: "),_c('strong',[_vm._v(_vm._s(_vm.enterprise.active ? "Ativa" : "Baixada"))])])]),_c('div',[_c('div',{staticClass:"mb-2"},[_vm._v(" Data de abertura: "),_c('strong',[_vm._v(_vm._s(_vm._f("date")(_vm.enterprise.founded_at)))])]),_c('div',[_vm._v(" Natureza Jurídica: "),_c('strong',[_vm._v(_vm._s(_vm.enterprise.segment))])])]),_c('div',{staticClass:"mb-2"},[_vm._v(" E-mail: "),_c('strong',[_vm._v(_vm._s(_vm.enterprise.email))])])]),_c('hr'),(_vm.enterprise && _vm.enterprise.address)?_c('div',[_c('strong',{staticClass:"d-block mb-1"},[_vm._v("Localização")]),_c('section',[_c('span',[_vm._v(" "+_vm._s(_vm.enterprise.address.place)+", "+_vm._s(_vm.enterprise.address.number)+", "+_vm._s(_vm.enterprise.address.city)+", "+_vm._s(_vm.enterprise.address.state)+" - "+_vm._s(_vm._f("VMask")(_vm.enterprise.address.zip_code,"#####-###"))+" ")])]),_c('gmap-map',{staticClass:"mt-3 w-100",staticStyle:{"height":"200px"},attrs:{"center":_vm.center,"zoom":14,"options":{
          strokeColor: '#347cff',
          zoomControl: false,
          scaleControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          disableDoubleClickZoom: true,
          draggable: false,
          scrollwheel: false,
          panControl: false,
        }}},[_c('gmap-marker',{attrs:{"position":_vm.center,"clickable":false,"draggable":false}})],1)],1):_vm._e(),_c('hr'),(_vm.enterprise && _vm.enterprise.responsibles)?_c('div',[_c('p',[_vm._v("Responsáveis legais")]),_vm._l((_vm.enterprise.responsibles),function(user,index){return _c('b-card',{key:index,staticClass:"my-3",attrs:{"body-class":"px-3 py-2"}},[_c('router-link',{attrs:{"to":`/users/detail/${user.id}`,"target":"_blank"}},[_c('div',{staticClass:"align-items-center"},[_c('section',{staticClass:"d-flex align-items-center gap-2"},[_c('avatar',{attrs:{"photo":user.photo,"is_online":user.is_online,"name":user.name}}),_c('div',[_c('strong',{staticClass:"d-block w-100"},[_vm._v(" "+_vm._s(user.name)+" ")]),_c('div',[_c('small',[_vm._v(_vm._s(user.email))])])])],1)])])],1)})],2):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }