<template>
  <b-card class="mb-3" body-class="d-flex">
    <div class="w-100">
      <div class="d-flex justify-content-between mb-4">
        <strong class="d-block text-left mb-1">
          Consulta de Crédito
        </strong>
        <router-link to="regularity">
          Resultado de Regularidade
        </router-link>
      </div>
      <div class="d-md-flex">
        <div class="text-center">
          <h6>Score</h6>
          <div
            ref="chatScore"
            class="mx-auto w-100 text-center my-4 chart-data"
          />
        </div>

        <section class="w-100">
          <b-table
            bordered
            :items="credit_inquirity.financial_pendencies"
            :fields="fields"
            small
          />
        </section>
      </div>
      <p>
        Realizamos a pesquisa no CNPJ da empresa prestadora de serviços, nos CPF
        e CNPJ dos sócios e em todos os CNPJ das empresas que possuem grupo
        econômico com o tomador.
      </p>
    </div>
  </b-card>
</template>

<script>
import { chartOptions } from "@/pages/Homologation/Services/Modules/Regularity/chartOptions";
import { mapGetters } from "vuex";
const GaugeChart = require("gauge-chart");

export default {
  props: {
    credit_inquirity: {
      type: Object,
    },
  },
  data() {
    return {
      chartOptions,

      fields: [
        {
          key: "message",
          label: "Pendências",
        },
        {
          key: "value",
          label: "Situação",
          formatter: (value) => (value ? "Há pendências" : "Nada Consta"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["is_dark", "is_mobile"]),
  },
  methods: {
    initPlugin(value) {
      let config = {
        needleColor: this.is_dark ? "white" : "black",
        chartWidth: this.is_mobile ? 340 : 400,
        needleStartValue: value,
        arcDelimiters: [20, 40, 60, 80],
        arcLabels: [200, 400, 600, 800],
        rangeLabel: ["0", "1000"],
        hasNeedle: true,
        arcColors: ["#f84438", "#ff5454", "#efd613", "#b7ec82", "#3dcc5b"],
        centralLabel: `${value}`,
        needleValue: value/10,
      };

      const element = this.$refs.chatScore;
      element.innerHTML = "";
      GaugeChart.gaugeChart(element, config.chartWidth, config).updateNeedle(
        config.needleValue
      );
    },
  },

  watch: {
    credit_inquirity: {
      handler(credit_inquirity) {
        if (credit_inquirity?.score) {
          const value = credit_inquirity.score.value ?? 0;
          this.initPlugin(value);
        }
      },
      immediate: true,
    },
  },
};
</script>
